import styled from 'styled-components'
import { Flex } from '@renderbus/common/components'
import { Media, typography, hideSMDown, hideSMUp } from '@renderbus/common/theme'

export const BannerContainer = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 75px;
  height: 600px;
  a {
    width: 140px;
    font-size: ${typography.text};
    color: #02ab5e;
    background: #fff;
    box-shadow: 1px 5px 15px 0px rgba(0, 145, 67, 0.2);
  }
  ${Media.lessThan(Media.small)} {
    padding-bottom: 190px;
    a {
      width: 100px;
      height: 28px;
      line-height: 28px;
      font-size: ${typography.textThin};
    }
  }
`
export const DetailContainer = styled(Flex)`
  width: 100%;
  padding: 70px 0;
  justify-content: center;
  background: linear-gradient(to right, #b4e4a1 48%, #fcdc8f 52%);
  ${Media.lessThan(Media.small)} {
    padding: 20px 10px;
  }
`
export const DetailWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 1200px;
  background: #fff;
  border-radius: 60px;
  padding: 55px 110px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: 30px;
    border-radius: 40px;
  }
`
export const TitleImg = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 100%;
    img {
      width: 280px;
    }
  }
`
export const StepsImgBox = styled.div`
  margin: 60px 0 72px 0;
  ${hideSMDown};
`
export const StepsImgMobileBox = styled.div`
  margin: 22px 0 36px 0;
  img {
    width: 100%;
  }
  ${hideSMUp};
`
export const RuleWrapper = styled.div`
  margin: 47px 0 0 -90px;
  p {
    margin: 0;
    line-height: 24px;
    color: #1e000a;
    font-size: ${typography.textSmall};
    font-weight: bold;
    color: #1e000a;
  }
  ${Media.lessThan(Media.small)} {
    margin: 22px 0 0 0;
    p {
      word-wrap: break-word;
    }
  }
`
